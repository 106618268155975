var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"preview-template-view",attrs:{"element-loading-text":_vm.loadingText}},[_c('div',{staticClass:"template-body-section"},[(_vm.interactiveQuestionsMode)?_c('el-form',{nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticStyle:{"width":"100%","overflow-x":"hidden"}},[(_vm.isAllQuestionsAnswered)?_c('div',{staticStyle:{"text-align":"center"}},[_c('h2',[_vm._v(" You have answered all questions. Please click on '"+_vm._s(_vm.buttonText)+"'. ")]),_c('el-button',{attrs:{"type":"primary","plain":"","size":"mini","loading":_vm.buttonLoading},on:{"click":_vm.goToReview}},[_vm._v("Stay & Review")]),_c('el-button',{attrs:{"type":"primary","size":"mini","loading":_vm.buttonLoading},on:{"click":_vm.emitFinishEvent}},[_vm._v(_vm._s(_vm.buttonText))])],1):_c('preview-interactive-template-form',{attrs:{"fieldsData":_vm.fieldsData,"isExecute":_vm.isExecute,"form":_vm.form,"interactiveQuestionsModeType":_vm.interactiveQuestionsModeType,"dataId":_vm.entityDataId,"isFormFormbuilder":_vm.isFormFormbuilder},on:{"onFilledQuestion":_vm.updateInteractiveQuestionFillingStatus}})],1)]):_c('el-form',{nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('div',{ref:"formBuilder",staticClass:"form-builder"},[_c('div',{staticClass:"paragraphScroll"},[(
              _vm.fieldsData &&
              _vm.fieldsData.length &&
              !_vm.refreshTemplate &&
              !_vm.hasRepeatableTemplate
            )?_c('div',{staticClass:"form-fields-holder"},_vm._l((_vm.fieldsData),function(field,index){return _c('div',{key:index,style:(_vm.getExistedStyle(field) || _vm.getStyle(field, index))},[(field.input_type === 'HORIZONTAL_LINE')?_c('HorizontalLineExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field)}}):_vm._e(),(field.input_type === 'DATE' && !_vm.checkhideFields(field))?_c('DateExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'TIME' && !_vm.checkhideFields(field))?_c('TimeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'TIME_RANGE' && !_vm.checkhideFields(field)
                )?_c('TimeRangeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel}}):_vm._e(),(
                  field.input_type === 'DATE_TIME' && !_vm.checkhideFields(field)
                )?_c('DateTimeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'DATE_TIME_RANGE' &&
                  !_vm.checkhideFields(field)
                )?_c('DateTimeRangeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'DATE_RANGE' && !_vm.checkhideFields(field)
                )?_c('DateRangeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'FILE' && !_vm.checkhideFields(field))?_c('FileExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field),"isExecute":true}}):_vm._e(),(
                  (field.input_type === 'MULTI_LINE_TEXT' ||
                    field.input_type === 'RICH_TEXT') &&
                  !_vm.checkhideFields(field)
                )?_c('MultiLineTextExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  (field.input_type === 'SINGLE_LINE_TEXT' ||
                    field.input_type === 'EMAIL' ||
                    field.input_type === 'PASSWORD' ||
                    field.input_type === 'MASKED' ||
                    field.input_type === 'HYPER_LINK') &&
                  !_vm.checkhideFields(field)
                )?_c('SingleLineTextExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'MULTI_SELECT' &&
                  !_vm.checkhideFields(field)
                )?_c('MultiSelectExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'SELECT' && !_vm.checkhideFields(field)
                )?_c('SelectExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'YES_OR_NO' && !_vm.checkhideFields(field)
                )?_c('YesOrNoExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'NUMBER' && !_vm.checkhideFields(field)
                )?_c('NumberExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'PHONE_COUNTRY_CODE' &&
                  !_vm.checkhideFields(field)
                )?_c('PhoneCountryCodeExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"checkIsDisabled":_vm.checkIsDisabled(field),"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'CURRENCY' && !_vm.checkhideFields(field)
                )?_c('CurrencyExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'HEADING' && !_vm.checkhideFields(field)
                )?_c('HeadingExecute',{attrs:{"data":field,"form":_vm.form,"label":_vm.getFieldLabelFromRules(field),"is-view":!_vm.isExecute}}):_vm._e(),(
                  field.input_type === 'CHECKBOX' && !_vm.checkhideFields(field)
                )?_c('CheckBoxExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.getFieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field),"singleCompanyTemplateCheckBoxArray":_vm.singleCompanyTemplateCheckBoxArray},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'RADIO' && !_vm.checkhideFields(field))?_c('RadioExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.getFieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'RADIO_BUTTON_GROUP' &&
                  !_vm.checkhideFields(field)
                )?_c('RadioGroupExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.getFieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'SIGNATURE')?_c('ESignatureExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field)}}):_vm._e(),(
                  field.input_type === 'GLOBAL_VARIABLE' &&
                  !_vm.checkhideFields(field)
                )?_c('GlobalVariableExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"fieldsData":_vm.fieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'ENTITY_VARIABLE' &&
                  !_vm.checkhideFields(field)
                )?_c('EntityVariableExecute',{class:'dataTable' + index,attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.fieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"applyFormRules":_vm.rulesEventEmitter,"updateTableData":_vm.updateTableFieldData,"onNewRowAdded":_vm.updateTopForBelowElements}}):_vm._e(),(
                  field.input_type === 'ENTITY' && !_vm.checkhideFields(field)
                )?_c('EntityExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.fieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'PARAGRAPH')?_c('ParagraphExecute',{attrs:{"data":field,"form":_vm.form,"label":_vm.getFieldLabelFromRules(field),"is-view":!_vm.isExecute}}):_vm._e(),(field.input_type === 'SINGLE_LINE_CONTENT')?_c('SingleLineContentExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel}}):_vm._e(),(field.input_type === 'AUTHORIZED_SIGNATURE')?_c('AuthorizedSignatureExecute',{attrs:{"data":field,"form":_vm.form,"label":_vm.getFieldLabelFromRules(field),"is-view":!_vm.isExecute}}):_vm._e(),(
                  field.input_type === 'FORMULA' && !_vm.checkhideFields(field)
                )?_c('FormulaExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"entityDataId":_vm.entityDataId,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'ACTION_BUTTON' &&
                  !_vm.checkhideFields(field)
                )?_c('ActionButtonExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"workflowDataId":_vm.entityDataId,"label":_vm.getFieldLabelFromRules(field),"fieldsData":_vm.fieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'IMAGE')?_c('ImageExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel}}):_vm._e(),(field.input_type === 'VIDEO')?_c('VideoExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"hasLabel":_vm.hasLabel}}):_vm._e(),(field.input_type === 'HTML_CONTENT')?_c('HtmlExecute',{attrs:{"data":field,"form":_vm.form,"label":_vm.getFieldLabelFromRules(field),"is-view":!_vm.isExecute}}):_vm._e(),(field.input_type === 'LIST' && !_vm.checkhideFields(field))?_c('ListExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'AGGREGATE_FUNCTION' &&
                  !_vm.checkhideFields(field)
                )?_c('AggregateFunctionExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"fieldsData":_vm.fieldsData,"label":_vm.getFieldLabelFromRules(field),"workflowDataId":_vm.entityDataId,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'QUESTION'&&
                  !_vm.checkhideFields(field))?_c('QuestionExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                  field.input_type === 'CHECKBOX_GROUP' &&
                  !_vm.checkhideFields(field)
                )?_c('CheckBoxGroupExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"fieldsData":_vm.getFieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'AUTO_INCREMENT_NUMBER' &&  !_vm.checkhideFields(field))?_c('AutoIncrementExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"entityId":_vm.entityId,"label":_vm.getFieldLabelFromRules(field),"isHideField":_vm.checkhideFields(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type == 'WEEKDAYS' && !_vm.checkhideFields(field))?_c('WeekDaysExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"is-view":!_vm.isExecute,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                field.input_type === 'STAR_RATING' &&
                !_vm.checkhideFields(field)
              )?_c('StarRatingExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.getFieldsData,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'PAY_BUTTON')?_c('PaymentExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecute,"hasLabel":_vm.hasLabel,"checkIsDisabled":_vm.checkIsDisabled(field),"fieldsData":_vm.getFieldsData,"entityId":_vm.entityId,"entityDataId":_vm.entityDataId,"currentEntity":_vm.currentEntity,"currentStep":_vm.currentStep,"type":_vm.type,"label":_vm.getFieldLabelFromRules(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'DATA_TABLE' &&
                !_vm.checkhideFields(field))?_c('DataTableExecute',{ref:"dataTable",refInFor:true,class:'dataTable'+index,attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.fieldsData,"entityDataId":_vm.entityDataId,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"updateTableData":_vm.updateTableFieldData,"onNewRowAdded":function($event){return _vm.updateTopForBelowElements(index)},"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(field.input_type === 'ENTITY_TABLE')?_c('EntityTableExecute',{ref:"dataTable",refInFor:true,class:'dataTable'+index,attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.fieldsData,"entityDataId":_vm.entityDataId,"label":_vm.getFieldLabelFromRules(field)},on:{"updateTableData":_vm.updateTableFieldData,"onNewRowAdded":function($event){return _vm.updateTopForBelowElements(index)}}}):_vm._e(),(field.input_type === 'PAYMENT_VARIABLE')?_c('PayVariableExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"fieldsData":_vm.fieldsData,"isView":!_vm.isExecute,"checkIsDisabled":_vm.checkIsDisabled(field),"label":_vm.getFieldLabelFromRules(field)},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                    field.input_type === 'CONCATENATE' && !_vm.checkhideFields(field)
                  )?_c('ConcatenateExecute',{attrs:{"data":field,"form":_vm.form,"fieldsData":_vm.getFieldsData,"hasLabel":_vm.hasLabel,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                    field.input_type == 'AUDIO' && !_vm.checkhideFields(field)
                  )?_c('AudioExecute',{attrs:{"data":field,"form":_vm.form,"is-view":!_vm.isExecuteCopy,"hasLabel":!_vm.hasLabel,"colorFields":_vm.colorFields,"label":_vm.getFieldLabelFromRules(field),"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                 field.input_type === 'CAPTCHA' &&
                 !_vm.checkhideFields(field)
                )?_c('CaptchaExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.getFieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
                 field.input_type === 'QR' &&
                 !_vm.checkhideFields(field)
                )?_c('QRExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.getFieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e(),(
             field.input_type === 'QR_READER' &&
             !_vm.checkhideFields(field)
            )?_c('QRReaderExecute',{attrs:{"data":field,"form":_vm.form,"hasLabel":_vm.hasLabel,"isView":!_vm.isExecute,"fieldsData":_vm.getFieldsData,"checkIsDisabled":_vm.checkIsDisabled(field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e()],1)}),0):_vm._e(),(_vm.hasRepeatableTemplate&& !_vm.checkhideFields(_vm.field))?_c('RepeatableTemplateExecute',{attrs:{"templateData":_vm.templateData,"entityRepeatableTemplateData":_vm.entityRepeatableTemplateData,"baseWidth":_vm.currentFormBuilderWidth,"form":_vm.form,"entitiesData":_vm.entitiesData,"isSubmit":_vm.isSubmit,"isView":!_vm.isExecute,"getDefaultDateFormat":_vm.getDefaultDateFormat,"checkIsDisabled":_vm.checkIsDisabled(_vm.field)},on:{"applyFormRules":_vm.rulesEventEmitter}}):_vm._e()],1)])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }